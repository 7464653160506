import React, { useEffect, useState } from 'react';
import { check } from '../helpers/helper';
import { anim } from '../helpers/anim';
import './styles.scss';

// мок данных из бд
const winWord = 'сон';
const letters = ['С', 'О', 'А', 'Н'];
const number = 3;
const win = 'Вы выиграли';
const lose = ' - неверное слово';

function Game() {
  const [selectedLetters, setSelectedLetters] = useState([]);
  const [message, setMessage] = useState('');

  const victorySound = new Audio('/sounds/win.mp3');

  const handleLetterClick = (letter) => {
    if (message) {
      setMessage('');
      setSelectedLetters([letter]);
    }
    if (selectedLetters?.length <= number - 1) {
      setSelectedLetters([...selectedLetters, letter]);
    }
  };

  useEffect(() => {
    if (selectedLetters?.length === number) {
      if (check(selectedLetters, winWord)) {
        setMessage(win);
        anim();
        victorySound.play();
      } else {
        setMessage(`${selectedLetters.join('')}${lose}`);
      }
    }
  }, [selectedLetters]);

  return (
    <div className="game">
      <div className="background" />
      <div className="game--answer">
        {Array.from({ length: number }, (_, index) => (
          <div key={index} className="game--answer--square">
            {selectedLetters[index] || ''}
          </div>
        ))}
      </div>
      <div className="game--result">
        <p>{message}</p>
      </div>
      <div className="game--letters">
        {letters.map((letter) => (
          <div
            key={letter}
            className="game--letter"
            onClick={() => handleLetterClick(letter)}
          >
            <span>{letter}</span>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Game;
