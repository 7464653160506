import confetti from 'canvas-confetti';

export const anim = () => {
  const duration = 2 * 1000;
  const animationEnd = Date.now() + duration;
  let skew = 1;

  function randomInRange(min, max) {
    return Math.random() * (max - min) + min;
  }

  (function frame() {
    const timeLeft = animationEnd - Date.now();
    const ticks = Math.max(500, 1000 * (timeLeft / duration));
    skew = Math.max(0.8, skew - 0.001);

    confetti({
      particleCount: 1,
      startVelocity: 0,
      ticks: ticks,
      origin: {
        x: Math.random(),
        y: Math.random() * skew - 1,
      },
      colors: ['#ffffff'],
      shapes: ['square'],
      gravity: randomInRange(0.7, 1.5),
      scalar: randomInRange(0.6, 1.4),
      drift: randomInRange(-0.9, 0.9),
    });

    if (timeLeft > 0) {
      requestAnimationFrame(frame);
    }
  })();
};
